<template>
  <button class="social-button">
    <component :is="iconComponent" />
    {{ buttonLabel }}
  </button>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { upperFirst } from 'lodash'

import AppleIcon from './logos/apple.svg'
import GoogleIcon from './logos/google.svg'

import { SocialProvider } from './utils/types'

type Props = {
  provider: SocialProvider
  label?: string
}
const props = defineProps<Props>()

const buttonLabel = computed(
  () => props.label || `Sign in with ${upperFirst(props.provider)}`,
)

const iconComponent = computed(() => {
  switch (props.provider) {
    case SocialProvider.Apple:
      return AppleIcon
    case SocialProvider.Google:
      return GoogleIcon
    default:
      return null
  }
})

defineOptions({
  name: 'UISocialSignInButton',
})
</script>

<style scoped lang="postcss">
.social-button {
  @apply w-full py-2 px-3;
  @apply inline-flex items-center justify-start gap-3;
  @apply border rounded-md outline-none;
  @apply border-gray-700 dark:border-gray-300;
  @apply bg-white dark:bg-gray-900;
  @apply text-gray-900 dark:text-white;
  @apply hover:bg-gray-100 dark:hover:bg-gray-800;
  @apply text-sm font-medium;
  @apply focus:outline-none;
  @apply focus:ring-2 focus:ring-offset-2;
  @apply focus:ring-indigo-500 dark:focus:ring-indigo-400;

  svg {
    @apply w-5 h-5;
  }
}
</style>
