<template>
  <UIButtonIcon
    v-bind="{ leading }"
    fill="light"
    size="xs"
    class="ui-button-menu"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { EllipsisVerticalIcon, XMarkIcon } from '@heroicons/vue/24/outline'
import { UIButtonIcon } from '@ui/buttons'

type Props = {
  open?: boolean
}

const props = defineProps<Props>()

const leading = computed(() => (props.open ? XMarkIcon : EllipsisVerticalIcon))
</script>

<style>
.ui-button-menu {
  @apply px-0.5;

  .ui-button {
    @apply w-5;
    @apply !aspect-auto;
    @apply focus:ring-1 focus:ring-offset-1;
  }
}
</style>
