<template>
  <UIButtonIcon
    v-bind="{ leading }"
    fill="light"
    size="xxs"
    class="ui-button-panel"
    @click="handleClick"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue'

import PanelOpenIcon from '@/assets/images/icons/panel-open.svg'
import PanelCloseIcon from '@/assets/images/icons/panel-close.svg'
import { UIButtonIcon } from '@ui/buttons'

const modelValue = defineModel<boolean>()

const leading = computed(() =>
  modelValue.value ? PanelCloseIcon : PanelOpenIcon,
)

const handleClick = () => {
  modelValue.value = !modelValue.value
}
</script>

<style>
.ui-button-panel {
  .ui-button__icon {
    @apply size-4;
  }
}
</style>
