<template>
  <UIButton
    ref="buttonRef"
    :leading="ChevronDoubleLeftIcon"
    fill="light"
    class="ui-button-back"
    @mouseup.stop
    @mousedown.stop
  />
</template>

<script setup lang="ts">
import { useTemplateRef } from 'vue'

import { ChevronDoubleLeftIcon } from '@heroicons/vue/20/solid'
import UIButton from './Button.vue'

const buttonRef = useTemplateRef('buttonRef')

defineExpose({
  focus() {
    buttonRef.value?.focus()
  },
})
</script>

<style scoped>
.ui-button-back {
  @apply h-auto;
  @apply p-0;
  @apply !bg-transparent;
  @apply !border-none;
  @apply font-medium;
  @apply uppercase;
  @apply focus:ring-0 focus:ring-offset-0;

  :deep() .ui-button__label {
    @apply text-xs;
    @apply text-left;
  }
}
</style>
