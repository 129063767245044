<template>
  <component :is="component" v-bind="{ to, href }">
    <UIButton v-bind="$attrs" ref="buttonRef" />
  </component>
</template>

<script setup lang="ts">
import { computed, useTemplateRef } from 'vue'
import { RouteRecordName, RouteParams } from 'vue-router'

import UIButton from './Button.vue'

type Props = {
  href?: string
  to?: {
    name: RouteRecordName
    params?: RouteParams
  }
}

const props = defineProps<Props>()

const buttonRef = useTemplateRef('buttonRef')

defineExpose({
  focus() {
    buttonRef.value?.focus()
  },
})

const component = computed(() => {
  return props.to ? 'RouterLink' : props.href ? 'a' : undefined
})
</script>
